import { NamedLink } from '../../components';
import css from './LandingPage.module.css';

export const LandingHeroText = ({ title, ctaText, ctaRouteName, children }) => (
  <div className={css.sectionHero}>
    <h2 className={css.sectionHero__title}>{title}</h2>
    <div className={css.sectionHero__text}>{children}</div>
    <div className={css.sectionHero__ctaButton}>
      <NamedLink name={ctaRouteName || 'LandingPage'}>{ctaText}</NamedLink>
    </div>
  </div>
);
