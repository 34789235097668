import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import facebookImage from '../../assets/PagePreview-Facebook.jpg';
import twitterImage from '../../assets/PagePreview-Twitter.jpg';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '../../components';
import config from '../../config';
import { TopbarContainer } from '../../containers';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { loadData as ExpertsListingPageLoader } from './../ExpertsListingPage/ExpertsListingPage.duck';

import { loadData as SearchPageLoader } from './../SearchPage/SearchPage.duck';
import css from './LandingPage.module.css';
import { SectionExampleOverview } from './SectionExampleOverview';
import { SectionFreelancerOverview } from './SectionFreelancerOverview';
import { SectionJobOverview } from './SectionJobOverview';

export const LandingPageComponent = (props) => {
  const {
    intl,
    scrollingDisabled,
    expertListings,
    expertCount,
    countryCount,
    jobListings,
    exampleListings,
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    let ignore = false;
    dispatch(SearchPageLoader());
    dispatch(ExpertsListingPageLoader());
  }, []);

  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="LandingPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.staticPageWrapper}>
          <SectionFreelancerOverview experts={expertListings} expertCount={expertCount} countryCount={countryCount} />
          <SectionJobOverview jobs={jobListings} />
          <SectionExampleOverview examples={exampleListings} />
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

//<YoutubeVideo embedId="6T0HehEV828" />
const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {
  const {
    ExpertsListingPage: {
      currentPageResultIds: expertListingIds,
      totalAmountOfFreelancers,
      totalAmountOfFreelancerCountries
    },
    SearchPage: {
      currentPageResultIds: jobListingIds,
      currentExampleResultIds: exampleListingIds
    },
  } = state;

  const expertListings = getListingsById(state, expertListingIds);
  const exampleListings = getListingsById(state, exampleListingIds);
  const jobListings = getListingsById(state, jobListingIds);

  return {
    expertListings,
    expertCount: totalAmountOfFreelancers ?? '...',
    countryCount: totalAmountOfFreelancerCountries ?? '...',
    jobListings,
    exampleListings,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl)(
    LandingPageComponent
);

export default LandingPage;
