import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ExpertAvatarLarge, NamedLink } from '../../components';
import { formatMoney } from '../../util/currency';
import { getExpertSkillLabels } from '../../util/getExpertSkillLabels';
import { types as sdkTypes } from '../../util/sdkLoader';
import css from './LandingPage.module.css';
import { fetchFullName } from '../../util/data';

const { Money } = sdkTypes;

export const LandingExpertRow = ({ expert }) => {
  const intl = useIntl();

  const {
    attributes: {
      title: expertName,
      price,
      publicData: { country, jobTitle, userID: id },
    },
    author,
  } = expert;

 const [fullName, setFullName] = useState(expertName);

  useEffect(() => {
    let ignore = false;
    setFullName(expertName);
    fetchFullName(expert, expertName).then(result => {
      if(!ignore) {
        setFullName(result);
      }
    });
    return () => {
      ignore = true;
    };
  },[expert]);

  const currentHourlyRateAmount = price?.amount ?? 0;
  const currentHourlyRateCurrency = price?.currency ?? '';

  const skills = getExpertSkillLabels(expert).slice(0, 3);

  const hourlyRateAsMoney = new Money(currentHourlyRateAmount, currentHourlyRateCurrency);
  const hourlyRate =
    currentHourlyRateAmount <= 0
      ? ''
      : intl.formatMessage(
          { id: 'ExpertsListingCard.ratePerHour' },
          { formattedPrice: formatMoney(intl, hourlyRateAsMoney) }
        );

  return (
    <NamedLink name="ProfilePage" params={{ id }}>
      <div className={css.expertRow}>
        <div className={css.expertRow__meta}>
          <div className={css.expertRow__avatar}>
            <ExpertAvatarLarge author={author} fullName={fullName}/>
          </div>

          <div className={css.expertRow__name}>{fullName}</div>

          <div className={css.expertRow__profession}>{jobTitle}</div>
          <div className={css.expertRow__hourlyRate}>{hourlyRate}</div>

          <div className={css.expertRow__country}>{country}</div>
        </div>

        {skills.map((skill, idx) => (
          <div key={idx} className={css.expertRow_skill}>
            <span>{skill}</span>
          </div>
        ))}
      </div>
    </NamedLink>
  );
};
