import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import NamedLink from '../../components/NamedLink/NamedLink';
import { useTranslation } from '../../hooks';
import { LandingHeroText } from './LandingHeroText';
import { LandingJobRow } from './LandingJobRow';
import css from './LandingPage.module.css';

export const SectionJobOverview = ({ jobs }) => {
  const setTranslation = useTranslation('LandingPage');
  const title = setTranslation('jobTitle');
  const ctaText = setTranslation('jobCtaText');
  const ctaRouteName = 'NewListingPage';

  const jobsAreLoading = useSelector((rx) => rx.SearchPage.searchInProgress);

  return (
    <section className={css.landingSection}>
      <LandingHeroText title={title} ctaText={ctaText} ctaRouteName={ctaRouteName}>
        {setTranslation('jobPromoText')}
      </LandingHeroText>

      <div className={css.jobContainer}>
        {!jobsAreLoading ? (
          jobs?.slice(0, 3).map((job, i) => <LandingJobRow key={i} job={job} isExample={false} />)
        ) : (
          <p>
            <FormattedMessage id={'SearchFiltersMobile.loadingResults'} />
          </p>
        )}
      </div>

      <NamedLink className={css.localSecondaryButton} name="SearchPage">
        {setTranslation('showAllJobs')}
      </NamedLink>
    </section>
  );
};
