import css from './LandingPage.module.css'

export const IconWithText = ({ icon, text }) => {
  return (
    <div className={css.iconWithText}>
      <span className={css.icon}>{icon}</span>
      <span className={css.text}>{text}</span>
    </div>
  );
};
