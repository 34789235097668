import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { NamedLink, ResponsiveImage } from '../../components';
import IconDoneJob from '../../components/IconDoneJob/IconDoneJob';
import Overlay from '../../components/Overlay/Overlay';
import { selectCurrentUserSubscription } from '../../ducks/user.duck';
import { useDateFormatter } from '../../hooks';
import { getStockImageForListing } from '../../util/getStockImageForListing';
import { FormattedMessage } from '../../util/reactIntl';
import { createSlug } from '../../util/urlHelpers';
import css from './LandingPage.module.css';

export const LandingJobRow = ({ job, isExample }) => {
  if (!job) {
    return null;
  }

  const dateFormatter = useDateFormatter({
    month: 'short',
    day: 'numeric',
  });

  const intl = useIntl();

  const {
    attributes: {
      title,
      publicData: { listingEndDateUTC },
    },
    id: { uuid: listingId },
    images,
  } = job;

  const subscription = useSelector(selectCurrentUserSubscription);
  const isPremiumSubscription = subscription === 'gold' || subscription === 'silver';
  const stockImageSrc = !isPremiumSubscription && getStockImageForListing(job);

  const [deadline] = dateFormatter(listingEndDateUTC, 'ListingCard.applicationDeadline');
  const deadlineExpired = intl.formatMessage({id:'ListingPage.expiredMessage'});
  const slug = createSlug(title);
  const firstImage = images[0];

  const ctaButtonTranslationId = !isExample ? "ListingCard.connectLink" :"ExampleCard.details"

  return (
    <ExampleOrJobLink isExample={isExample} listingId={listingId} slug={slug}>
    <div className={css.jobRow}>
      <div className={css.jobRow__image}>
        <ExampleOrJobImageContainer
          isExample={isExample}
          intl={intl}
          stockImageSrc={stockImageSrc}
          title={title}
        >
          <ResponsiveImage alt={title} image={firstImage} variants={['landscape-crop']} />
        </ExampleOrJobImageContainer>
      </div>
      <div className={css.jobRow__content}>
        <p>{title}</p>
        <p>
          <b>
            {!isExample
              ? deadline
              : <FormattedMessage id={'ListingPage.expiredMessage'}/>}

          </b>
        </p>
      </div>
      <div className={css.jobRow__cta}>
        <span className={css.localSecondaryButton}>
          <FormattedMessage id={ctaButtonTranslationId}/>
        </span>

      </div>
    </div>
    </ExampleOrJobLink>
  );
};

const ExampleOrJobLink = ({ isExample, listingId, slug, children }) =>
  !isExample ? (
    <NamedLink

      name="ListingPage"
      params={{ id: listingId, slug }}
    >
      {children}
    </NamedLink>
  ) : (
    <NamedLink
      name="ExamplePage"
      params={{ id: listingId, slug }}
    >
      {children}
    </NamedLink>
  );

const ExampleOrJobImageContainer = ({ isExample, children, intl, stockImageSrc, title }) =>
  !isExample ? (
    !!stockImageSrc ? (
      <img src={stockImageSrc} alt={title} />
    ) : (
      children
    )
  ) : (
    <Overlay
      rootClassName={css.smallOverlay}
      icon={<IconDoneJob />}
      message={intl.formatMessage({ id: 'ExampleCard.overlayText' })}
    >
      {children}
    </Overlay>
  );
