import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import NamedLink from '../../components/NamedLink/NamedLink';
import { useTranslation } from '../../hooks';
import { IconCountries } from './IconCountries';
import { IconExperts } from './IconExperts';
import { IconWithText } from './IconWithText';
import { LandingExpertRow } from './LandingExpertRow';
import { LandingHeroText } from './LandingHeroText';
import css from './LandingPage.module.css';

export const SectionFreelancerOverview = ({ experts, expertCount, countryCount }) => {
  const setTranslation = useTranslation('LandingPage');
  const title = setTranslation('freelancerTitle');
  const ctaText = setTranslation('freelancerCtaText');
  const ctaRouteName = 'SubscriptionPage';

  const expertCountText = setTranslation('numberOfExperts', {
    amount: expertCount,
  });
  const numberOfCountriesText = setTranslation('numberOfCountries', {
    amount: countryCount,
  });

  const expertsAreLoading = useSelector((rx) => rx.ExpertsListingPage.searchInProgress);

  return (
    <section className={css.landingSection}>
      <LandingHeroText title={title} ctaText={ctaText} ctaRouteName={ctaRouteName}>
        {setTranslation('freelancerPromoText')}
      </LandingHeroText>

      <div className={css.iconContainer}>
        <NamedLink name={'ExpertsListingPage'}>
          <IconWithText icon={<IconExperts />} text={expertCountText} />
        </NamedLink>
        <NamedLink name={'ExpertsListingPage'}>
          <IconWithText icon={<IconCountries />} text={numberOfCountriesText} />
        </NamedLink>
      </div>

      <div>
        {!expertsAreLoading ? (
          experts?.slice(0, 3).map((x, i) => <LandingExpertRow key={i} expert={x} />)
        ) : (
          <p>
            <FormattedMessage id={'SearchFiltersMobile.loadingResults'} />
          </p>
        )}
      </div>

      <NamedLink className={css.localSecondaryButton} name="ExpertsListingPage">
        {setTranslation('showAllExperts')}
      </NamedLink>
    </section>
  );
};
