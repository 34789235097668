import { NamedLink } from '../../components';
import { useTranslation } from '../../hooks';
import partner from './../IndustrypartnerPage/logoConfig.json';
import { IconManufacturers } from './IconManufacturers';
import { IconWithText } from './IconWithText';
import { LandingHeroText } from './LandingHeroText';
import { LandingJobRow } from './LandingJobRow';
import css from './LandingPage.module.css';

export const SectionExampleOverview = ({ examples }) => {
  const setTranslation = useTranslation('LandingPage');
  const title = setTranslation('exampleTitle');
  const ctaText = setTranslation('exampleCtaText');
  const ctaRouteName = 'NewLeadPage';

  const numberOfPartners = partner.logos.length;

  const numberOfPartnersText = setTranslation('numberOfPartners', {
    amount: numberOfPartners,
  });

  return (
    <section className={css.landingSection}>
      <LandingHeroText title={title} ctaText={ctaText} ctaRouteName={ctaRouteName}>
        {setTranslation('examplePromoText')}
      </LandingHeroText>

      <div className={css.iconContainer}>
        <NamedLink name={'IndustrypartnerPage'}>
          <IconWithText icon={<IconManufacturers/>} text={numberOfPartnersText} />
        </NamedLink>
      </div>

      {examples?.slice(0, 3).map((x, i) => (
        <LandingJobRow key={i} job={x} isExample={true} />
      ))}

      <NamedLink className={css.localSecondaryButton} name="RequestManufacturerPage">
        {setTranslation('learnMore')}
      </NamedLink>
    </section>
  );
};
